<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row">
          <div class="col-6" v-if="!isLoading">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated centres
              <input
                type="checkbox"
                id="text"
                v-model="showDeactivated"
                @click="switchShowDeactivated()"
                class="form-"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-6" v-if="isLoading"></div>
          <div
            class="col-6 d-flex justify-content-end"
            v-if="userCanCreateCentres"
          >
            <button
              type="button"
              data-target="#newCentreModal"
              data-toggle="modal"
              @click="clearForm"
              class="button page__body__header__button button--grey text--capital"
            >
              <span class="d-inline-flex mr-2">add new centre</span>
              <ion-icon name="add-outline" class="text--medium"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              Centre name & ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              meeting day
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              Centre status
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              created
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              last modified
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 15%"
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in allCentres.items" v-bind:key="item.centreKey">
            <td>
              <div class="align-self-center" style="padding-right: 15px">
                <span
                  class="table__body__text table__text py-1 d-block text--upper"
                  style="word-break: break-word"
                >
                  {{ item.centreName }}
                </span>
                <span
                  class="table__body__text table__text d-block py-1 table__text--small text--capital"
                  style="word-break: break-all"
                >
                  {{ item.centreId }}
                </span>
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.meetingDay
              }}</span>
            </td>
            <td>
              <span
                v-if="item.centreStatus == 'Active'"
                class="table__body__text table__text text--capital text--success"
                >{{ item.centreStatus }}</span
              >
              <span
                v-if="item.centreStatus == 'Inactive'"
                class="table__body__text table__text text--capital text--danger"
                >deactivated</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.creationDate | moment
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                item.lastModifiedDate | moment
              }}</span>
            </td>
            <td>
              <div class="align-self-center dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#editCentreModal"
                    v-on:click="editCentre(item)"
                    v-if="userCanEditCentres"
                    >edit</a
                  >

                  <a
                    v-if="
                      item.centreStatus == 'Active' && userCanDeactivateCentres
                    "
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#activationConfirmModal"
                    v-on:click="openActivationConfirmation(item, 'deactivate')"
                  >
                    <span class="text--danger">Deactivate</span>
                  </a>

                  <a
                    v-if="
                      item.centreStatus == 'Inactive' && userCanActivateCentres
                    "
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#activationConfirmModal"
                    v-on:click="openActivationConfirmation(item, 'activate')"
                  >
                    <span class="text--success">Activate</span>
                  </a>

                  <!-- <a
                    class="dropdown-item text--capital"
                    data-toggle="modal"
                    data-target="#deleteConfirmModal"
                    v-on:click="openDeleteConfirmation(item)"
                    >delete</a
                  > -->
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="fetchModel.pageSize"
                  class="select select--lg select--page"
                  @change="getCentres()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="fetchModel.pageSize"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allCentres.pageIndex || allCentres.currentPage
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allCentres.totalPages
            }}</span>
            <!-- of
            <span>{{ allCentres.totalCount }}</span> -->
          </div>

          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
          >
            <button
              class="button button--sm text--regular"
              v-if="allCentres.hasPreviousPage"
              @click="changeCurrentPage('previous')"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-for="pages in allCentres.totalPages"
              :key="pages"
              :class="{
                'button--primary text--white':
                  allCentres.pageIndex === pages ||
                  allCentres.currentPage === pages,
              }"
              @click="changeCurrentPage(pages)"
            >
              {{ pages }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              v-if="allCentres.hasNextPage"
              @click="changeCurrentPage('next')"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal section starts here -->
    <div
      class="modal modal__right"
      id="newCentreModal"
      tabindex=""
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#newCentreModal"
                class="modal__close modal__close--black"
              >
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">New centre</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <label class="form__label">centre name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter centre name"
                  v-model="form.centreName"
                  v-bind:class="{
                    'input-required': formErr.centreName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch</label>
                <select
                  class="select--lg w-100"
                  v-model="form.branchKey"
                  v-bind:class="{
                    'input-required': formErr.branchKey,
                  }"
                >
                  <option disabled>Select branch</option>
                  <option
                    v-for="item in allBranches"
                    v-bind:key="item.branchKey"
                    v-bind:value="item.branchKey"
                  >
                    {{ item.branchName }} ({{ item.branchId }})
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">centre id</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter centre id"
                  v-model="form.centreId"
                  v-bind:class="{
                    'input-required': formErr.centreId,
                  }"
                  :maxlength="20"
                />
              </div>
              <div class="form__item">
                <label class="form__label">meeting day</label>
                <select
                  class="select--lg w-100"
                  v-bind:class="{
                    'input-required': formErr.meetingDay,
                  }"
                  v-model="form.meetingDay"
                >
                  <option>Select meeting day</option>
                  <option
                    v-for="item in meetingDays"
                    v-bind:key="item"
                    v-bind:value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">centre notes</label>
                <div id="content-container">
                  <textarea
                    v-model="form.centreNotes"
                    class="input form__input w-100"
                    placeholder="centre notes"
                    cols="100"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">centre status</label>
                <select
                  class="select-lg w-100"
                  v-model="form.centreStatus"
                  v-bind:class="{
                    'input-required': formErr.centreStatus,
                  }"
                >
                  <option disabled>Select option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <ul id="accordion" class="mb5">
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                  v-show="set.customFieldSetType.toLowerCase() === 'centres'"
                >
                  <li
                    v-if="set.customFields.length > 0"
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="field in set.customFields"
                          :key="field.customFieldKey"
                        >
                          <div class="form__item">
                            <label class="form__label">{{
                              field.customFieldName
                            }}</label>
                            <input
                              :type="
                                field.dataType === 'Number'
                                  ? 'number'
                                  : field.dataType === 'Date'
                                  ? 'date'
                                  : field.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="
                                field.dataType !== 'Selection' &&
                                field.dataType.toLowerCase() !== 'user link' &&
                                field.dataType.toLowerCase() !== 'group link' &&
                                field.dataType.toLowerCase() !== 'customer link'
                              "
                              :id="'new-' + field.customFieldKey"
                              :ref="field.customFieldKey"
                              v-model="field.customFieldValue"
                              class="input input--right form__input form__input--lg"
                              :placeholder="`Enter ${field.customFieldName}`"
                            />
                            <select
                              class="select--lg w-100"
                              v-if="
                                field.dataType === 'Selection' &&
                                field.dataType.toLowerCase() !== 'user link' &&
                                field.dataType.toLowerCase() !== 'group link' &&
                                field.dataType.toLowerCase() !== 'customer link'
                              "
                              :id="'new-' + field.customFieldKey"
                              :ref="field.customFieldKey"
                              v-model="field.customFieldValue"
                            >
                              <option disabled selected value="null">
                                Select {{ field.customFieldName }}
                              </option>
                              <option
                                v-for="opt in field.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>

                            <!-- User Multi select -->
                            <multiselect
                              v-model="field.customFieldValue"
                              :id="'new-' + field.customFieldKey"
                              :ref="field.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search user"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                field.dataType.toLowerCase() === 'user link'
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Group multiselect -->
                            <multiselect
                              v-model="field.customFieldValue"
                              :id="'new-' + field.customFieldKey"
                              :ref="field.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search group"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                field.dataType.toLowerCase() === 'group link'
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Customer multiselect -->
                            <multiselect
                              v-model="field.customFieldValue"
                              :id="'new-' + field.customFieldKey"
                              :ref="field.customFieldKey"
                              label="customerFirstName"
                              track-by="customerKey"
                              placeholder="Type to search customer"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                field.dataType.toLowerCase() === 'customer link'
                              "
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >
                                <span>{{ option.customerFirstName }}</span>
                                <span>&nbsp;{{ option.customerLastName }}</span>
                              </template>

                              <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                  <span class="option__title">
                                    {{ option.customerFirstName }}&nbsp;
                                  </span>
                                  <span class="option__small">{{
                                    option.customerLastName
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>

              <div class="form__item">
                <div class="form__action">
                  <button
                    class="button form__button form__button--lg w-30 save-changes"
                    type="submit"
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editCentreModal"
      tabindex=""
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#editCentreModal"
                class="modal__close modal__close--black"
              >
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton2"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">Edit centre</h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveEditForm">
              <div class="form__item">
                <label class="form__label">centre name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter centre name"
                  v-model="editForm.centreName"
                  v-bind:class="{
                    'input-required': editFormErr.centreName,
                  }"
                />
              </div>
              <div class="form__item">
                <label class="form__label">branch</label>
                <select
                  class="select--lg w-100"
                  v-model="editForm.branchKey"
                  v-bind:class="{
                    'input-required': editFormErr.branchKey,
                  }"
                >
                  <option disabled>Select branch</option>
                  <option
                    v-for="item in allBranches"
                    v-bind:key="item.branchKey"
                    v-bind:value="item.branchKey"
                  >
                    {{ item.branchName }} ({{ item.branchId }})
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">centre id</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter centre id"
                  v-model="editForm.centreId"
                  v-bind:class="{
                    'input-required': editFormErr.centreId,
                  }"
                  :maxlength="20"
                />
              </div>
              <div class="form__item">
                <label class="form__label">meeting day</label>
                <select class="select--lg w-100" v-model="editForm.meetingDay">
                  <option>Select meeting day</option>
                  <option
                    v-for="item in meetingDays"
                    v-bind:key="item"
                    v-bind:value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="form__item">
                <label class="form__label">centre notes</label>
                <div id="content-container">
                  <textarea
                    v-model="editForm.centreNotes"
                    class="input form__input w-100"
                    placeholder="centre notes"
                    cols="100"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">centre status</label>
                <select
                  class="select--lg w-100"
                  v-model="editForm.centreStatus"
                  v-bind:class="{
                    'input-required': editFormErr.centreStatus,
                  }"
                >
                  <option disabled>Select option</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>

              <ul id="accordion" class="mb5">
                <div
                  class="modal__accordion"
                  v-for="(set, index) in customFields"
                  :key="set.customFieldSetKey"
                  v-show="set.customFieldSetType.toLowerCase() === 'centres'"
                >
                  <li
                    v-if="set.customFields.length > 0"
                    data-toggle="collapse"
                    class="sidebar-right__item modal__accordion--toggle"
                    :data-target="`#ccSet${index}`"
                    aria-expanded="false"
                    aria-controls="ccprofileNote"
                  >
                    <h5
                      class="w-100 mb-0 d-flex align-items-center justify-content-between"
                    >
                      {{ set.customFieldSetName }}
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </h5>
                  </li>
                  <div
                    :id="`ccSet${index}`"
                    class="collapse"
                    data-parent="#accordion"
                  >
                    <div class="pt-5">
                      <div class="row">
                        <div
                          class="col-6"
                          v-for="form in set.customFields"
                          :key="form.customFieldKey"
                        >
                          <div class="form__item">
                            <label class="form__label">{{
                              form.customFieldName
                            }}</label>
                            <input
                              :type="
                                form.dataType === 'Number'
                                  ? 'number'
                                  : form.dataType === 'Date'
                                  ? 'date'
                                  : form.dataType === 'Checkbox'
                                  ? 'checkbox'
                                  : 'text'
                              "
                              v-if="
                                form.dataType !== 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              "
                              :id="form.customFieldKey"
                              v-model="form.customFieldValue"
                              class="input input--right form__input form__input--lg"
                              :placeholder="`Enter ${form.customFieldName}`"
                            />

                            <select
                              class="select--lg w-100"
                              v-if="
                                form.dataType === 'Selection' &&
                                form.dataType.toLowerCase() !== 'user link' &&
                                form.dataType.toLowerCase() !== 'group link' &&
                                form.dataType.toLowerCase() !== 'customer link'
                              "
                              :id="form.customFieldKey"
                              v-model="form.customFieldValue"
                            >
                              <option disabled selected value="null">
                                Select {{ form.customFieldName }}
                              </option>
                              <option
                                v-for="opt in form.customFieldSelections"
                                :key="opt.customFieldSelectionKey"
                                :value="opt.value"
                              >
                                {{ opt.value }}
                              </option>
                            </select>

                            <!-- User Multi select -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              label="name"
                              track-by="userKey"
                              placeholder="Type to search user"
                              open-direction="bottom"
                              :options="sortedUsers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="form.dataType.toLowerCase() === 'user link'"
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Group multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              label="groupName"
                              track-by="groupKey"
                              placeholder="Type to search group"
                              open-direction="bottom"
                              :options="sortedGroups"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                form.dataType.toLowerCase() === 'group link'
                              "
                            >
                              <template
                                slot="tag"
                                slot-scope="{ option, remove }"
                              >
                                <span class="custom__tag">
                                  <span>{{ option.name }}</span>
                                  <span
                                    class="custom__remove"
                                    @click="remove(option)"
                                    >❌</span
                                  >
                                </span>
                              </template>
                            </multiselect>

                            <!-- Customer multiselect -->
                            <multiselect
                              v-model="form.customFieldValue"
                              :id="form.customFieldKey"
                              label="customerFirstName"
                              track-by="customerKey"
                              placeholder="Type to search customer"
                              open-direction="bottom"
                              :options="sortedCustomers"
                              :multiple="false"
                              :searchable="true"
                              :internal-search="true"
                              :clear-on-select="false"
                              :close-on-select="true"
                              :options-limit="300"
                              :limit="3"
                              :max-height="800"
                              :show-no-results="false"
                              :hide-selected="true"
                              v-if="
                                form.dataType.toLowerCase() === 'customer link'
                              "
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >
                                <span>{{ option.customerFirstName }}</span>
                                <span>&nbsp;{{ option.customerLastName }}</span>
                              </template>

                              <template slot="option" slot-scope="{ option }">
                                <div class="option__desc">
                                  <span class="option__title">
                                    {{ option.customerFirstName }}&nbsp;
                                  </span>
                                  <span class="option__small">{{
                                    option.customerLastName
                                  }}</span>
                                </div>
                              </template>
                            </multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="form__item">
                <div class="form__action">
                  <button
                    class="button form__button form__button--lg w-30 save-changes"
                    type="submit"
                    v-if="!isSaving"
                  >
                    save changes
                  </button>
                  <!-- loader button -->
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                    :disabled="true"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Ends here -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="centreToDelete != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this centre
                  {{ centreToDelete.centreName }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this centre from your list, you won't be able
                  to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="deleteCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteCentre()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="activationConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div
          class="modal__dialog"
          role="document"
          v-if="activationCentre != null"
        >
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to {{ activationType }} this centre
                  {{ activationCentre.centreName }} ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4"></h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="activateCloseButton"
              >
                Cancel
              </button>

              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isActivating"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isActivating"
                v-on:click="activateCentre()"
              >
                {{ activationType }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewCentres"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import AccessApiService from "@/core/services/access.service";
import GeneralApiService from "@/core/services/general.service";
// import ApiService from "@/core/services/api.service";

export default Vue.extend({
  name: "OrganizationCentres",
  components: {
    DeleteModal,
    Multiselect,
  },
  data: function () {
    return {
      content_header: {
        title: "Centers",
        subTitle: "",
      },
      centreStatus: ["Active", "Inactive"],
      meetingDays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      showDeactivated: false,
      // Gap
      centre: null,
      allCentres: null,
      allBranches: [],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isActivating: false,
      centreToDelete: null,
      activationCentre: null,
      activationType: null,
      fetchModel: {
        centreName: null,
        centreStatus: "Active",
        pageIndex: 1,
        pageSize: 20,
      },
      customFields: [],
      form: {
        branchKey: "",
        centreId: "",
        meetingDay: "",
        centreName: "",
        centreNotes: "",
        centreStatus: "Active",
        customFieldValues: [],
      },
      formErr: {
        branchKey: false,
        centreId: false,
        meetingDay: false,
        centreName: false,
        centreNotes: false,
        centreStatus: false,
      },
      editForm: {
        centreKey: "",
        branchKey: "",
        centreId: "",
        meetingDay: "",
        centreName: "",
        centreNotes: "",
        centreStatus: "",
      },
      editFormErr: {
        centreKey: false,
        branchKey: false,
        centreId: false,
        meetingDay: false,
        centreName: false,
        centreNotes: false,
        centreStatus: false,
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      allActiveCustomers: [],
      allActiveGroups: [],
      allUsers: [],
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCentres() {
      this.allCentres = [];
      try {
        this.isLoading = true;
        const res = await centresService.get(this.fetchModel);
        this.allCentres = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
      if (!this.userCanViewCentres)
        return this.alertWarning("You do not have access to view this page");
    },
    changeCurrentPage(pages = null) {
      if (pages === "next") {
        this.fetchModel.pageIndex = this.allCentres.hasNextPage
          ? this.allCentres.pageIndex + 1
          : 0;
        this.getCentres();
      } else if (pages === "previous") {
        this.fetchModel.pageIndex = this.allCentres.hasPreviousPAge
          ? this.allCentres.pageIndex - 1
          : 0;
        this.getCentres();
      } else if (pages !== null) {
        this.getCentres();
      }
    },
    getCentreByKey(key) {
      try {
        this.isLoading = true;
        centresService.getByKey(key).then((res) => {
          this.editForm = res.data.data;
          const customFieldValues = this.editForm.customFieldSetFieldsAndValue;
          let customFields = [];

          this.customFields.forEach((item) => {
            const arr = [];

            item.customFields.forEach((field) => {
              const obj = { ...field, customFieldValue: "" };
              arr.push(obj);
            });

            const field = { ...item, customFields: arr };
            customFields.push(field);
          });

          customFieldValues.forEach((data) => {
            customFields.forEach((fields) => {
              if (fields.fieldSetKey === data.customFieldKey) {
                fields.customFields.forEach((res) => {
                  if (
                    ["customer link", "group link", "user link"].includes(
                      res.dataType.toLowerCase()
                    )
                  ) {
                    data.fields.forEach((result) => {
                      if (res.customFieldKey === result.fieldKey) {
                        if (res.dataType.toLowerCase() === "customer link") {
                          res.customFieldValue = this.sortedCustomers.find(
                            (cus) =>
                              cus.customerKey === result.value.split("-")[1]
                          );
                        }

                        if (res.dataType.toLowerCase() === "group link") {
                          res.customFieldValue = this.sortedGroups.find(
                            (group) =>
                              group.groupKey === result.value.split("-")[1]
                          );
                        }

                        if (res.dataType.toLowerCase() === "user link") {
                          res.customFieldValue = this.sortedUsers.find(
                            (user) =>
                              user.userKey === result.value.split("-")[1]
                          );
                        }
                      }
                    });
                  } else {
                    data.fields.forEach((result) => {
                      if (res.customFieldKey === result.fieldKey) {
                        res.customFieldValue = result.value;
                      }
                    });
                  }
                });
              }
            });
          });

          this.customFields = customFields;
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editCentre(centre) {
      try {
        this.clearForm();
        this.isLoading = true;
        this.centre = centre;
        this.editForm = {
          centreKey: centre.centreKey,
          branchKey: centre.branchKey,
          centreId: centre.centreId,
          meetingDay: centre.meetingDay,
          migrationEventKey: centre.migrationEventKey,
          centreName: centre.centreName,
          centreNotes: centre.centreNotes,
          centreStatus: centre.centreStatus,
        };
        this.isLoading = false;
        this.getCentreByKey(centre.centreKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getAllUsers() {
      this.allUsers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageNumber: 1,
          pageSize: 1000000,
          branchKey: "",
          status: true,
          filters: [],
        };
        AccessApiService.post(
          "ProleanUser/GetUsersWithoutPermission",
          userModel
        ).then((resp) => {
          this.allUsers = resp.data.data;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    async getAllCustomers() {
      this.allActiveCustomers = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 100000,
          filters: [],
          customerStatus: "Active",
        };
        GeneralApiService.post("Customer/customers", userModel).then((resp) => {
          this.allActiveCustomers = resp.data.data.items;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    async getAllGroups() {
      this.allActiveGroups = [];
      try {
        this.userLoading = true;
        const userModel = {
          pageIndex: 1,
          pageSize: 1000000,
          filter: "",
          filters: [],
        };
        GeneralApiService.post("Group/Groups", userModel).then((resp) => {
          this.allActiveGroups = resp.data.data.items;
          this.userLoading = false;
        });
      } catch (e) {
        const error = e.response.data.message;
        this.userLoading = false;
        this.$emit("alertError", error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          this.form.customFieldValues = [];
          let cForm = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                if (form.customFieldValue) {
                  let key = form.customFieldKey;

                  let value = "";

                  if (form.dataType.toLowerCase() === "group link") {
                    value = form.customFieldValue.groupKey;
                  } else if (form.dataType.toLowerCase() === "user link") {
                    value = form.customFieldValue.userKey;
                  } else if (form.dataType.toLowerCase() === "customer link") {
                    value = form.customFieldValue.customerKey;
                  } else {
                    value = form.customFieldValue;
                  }

                  const formValObj = {
                    name: form.customFieldName,
                    customFieldKey: key,
                    value: value,
                  };
                  cForm.push({ ...formValObj });

                  this.form.customFieldValues = cForm;
                }
              });
            });
          }
          await centresService.create(this.form);
          this.getCentres();
          this.clearForm();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New centre added.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateEditForm()) {
        try {
          this.isSaving = true;
          this.editForm.customFieldValues = [];
          let cForm = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                if (form.customFieldValue) {
                  let key = form.customFieldKey;

                  let value = "";

                  if (form.dataType.toLowerCase() === "group link") {
                    value = form.customFieldValue.groupKey;
                  } else if (form.dataType.toLowerCase() === "user link") {
                    value = form.customFieldValue.userKey;
                  } else if (form.dataType.toLowerCase() === "customer link") {
                    value = form.customFieldValue.customerKey;
                  } else {
                    value = form.customFieldValue;
                  }

                  const formValObj = {
                    name: form.customFieldName,
                    customFieldKey: key,
                    value: value,
                    scoreAmount: 0,
                    indexInList: 0,
                    customFieldSetGroupIndex: 0,
                  };
                  cForm.push({ ...formValObj });

                  this.editForm.customFieldValues = cForm;
                }
              });
            });
          }
          await centresService.update(this.editForm.centreKey, this.editForm);
          this.getCentres();
          this.clearForm();
          document.getElementById("formCloseButton2").click();
          this.alertSuccess("Centre updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    openDeleteConfirmation(centre) {
      this.centreToDelete = centre;
    },
    async deleteCentre() {
      if (this.centreToDelete != null) {
        this.isDeleting = true;
        try {
          await centresService.delete(this.centreToDelete.centreKey);
          this.centreToDelete = null;
          document.getElementById("deleteCloseButton").click();
          this.alertSuccess("Centre deleted");
          this.isDeleting = false;
          this.getCentres();
        } catch (e) {
          this.isDeleting = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },
    openActivationConfirmation(centre, type) {
      this.activationCentre = centre;
      this.activationType = type;
    },
    async activateCentre() {
      if (this.activationCentre != null) {
        this.isActivating = true;
        try {
          if (this.activationType == "activate") {
            await centresService.activate(this.activationCentre.centreKey);
            this.activationCentre = null;
            document.getElementById("activateCloseButton").click();
            this.alertSuccess("Centre activated");
          } else {
            await centresService.deactivate(this.activationCentre.centreKey);
            this.activationCentre = null;
            document.getElementById("activateCloseButton").click();
            this.alertSuccess("Centre deactivated");
          }
          this.isActivating = false;
          this.getCentres();
        } catch (e) {
          this.isActivating = false;
          const error = e.response.data.message || "Something went wrong";
          this.alertError(error);
        }
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    async getCustomFormFields(type) {
      try {
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    clearForm: function () {
      this.form = {
        branchKey: "",
        centreId: "",
        meetingDay: "",
        migrationEventKey: "",
        centreName: "",
        centreNotes: "",
        centreStatus: "Active",
      };
      this.editForm = {
        centreKey: "",
        branchKey: "",
        meetingDay: "",
        migrationEventKey: "",
        centreName: "",
        centreNotes: "",
        centreStatus: "",
      };
      this.getCustomFormFields("Centres");
    },
    clearFormValidation() {
      this.formErr = {
        branchKey: false,
        centreId: false,
        meetingDay: false,
        centreName: false,
        centreNotes: false,
        centreStatus: false,
      };
    },
    clearEditFormValidation() {
      this.editFormErr = {
        centreKey: false,
        branchKey: false,
        centreId: false,
        meetingDay: false,
        centreName: false,
        centreNotes: false,
        centreStatus: false,
      };
    },
    validateForm() {
      this.clearFormValidation();
      if (
        this.form.centreName &&
        this.form.branchKey &&
        this.form.centreId &&
        this.form.centreStatus &&
        this.form.meetingDay
      ) {
        return true;
      }

      if (!this.form.centreName) {
        this.formErr.centreName = true;
      }
      if (!this.form.branchKey) {
        this.formErr.branchKey = true;
      }
      if (!this.form.centreId) {
        this.formErr.centreId = true;
      }
      if (!this.form.centreStatus) {
        this.formErr.centreStatus = true;
      }
      if (!this.form.meetingDay) {
        this.formErr.meetingDay = true;
      }
      return false;
    },
    validateEditForm() {
      this.clearEditFormValidation();
      if (
        this.editForm.centreName &&
        this.editForm.branchKey &&
        this.editForm.centreId &&
        this.editForm.centreStatus &&
        this.editForm.meetingDay
      ) {
        return true;
      }

      if (!this.editForm.centreName) {
        this.editFormErr.centreName = true;
      }
      if (!this.editForm.branchKey) {
        this.editFormErr.branchKey = true;
      }
      if (!this.editForm.centreId) {
        this.editFormErr.centreId = true;
      }
      if (!this.editForm.centreStatus) {
        this.editFormErr.centreStatus = true;
      }
      if (!this.editForm.meetingDay) {
        this.editFormErr.centreStatus = true;
      }

      return false;
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    switchShowDeactivated() {
      this.showDeactivated = !this.showDeactivated;
      if (this.showDeactivated) {
        this.fetchModel.centreStatus = null;
      } else {
        this.fetchModel.centreStatus = "Active";
      }
      this.getCentres();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanViewCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanViewCentre;
    },
    userCanEditCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanEditCentre;
    },
    userCanCreateCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanCreateCentre;
    },
    userCanDeleteCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanDeleteCentre;
    },
    userCanActivateCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanActivateCentre;
    },
    userCanDeactivateCentres() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Centre
      );
      const { permissionsList } = user;
      return permissionsList.CanDeactivateCentre;
    },

    sortedUsers() {
      const userArray = this.allUsers;
      return userArray.sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedCustomers() {
      const userArray = this.allActiveCustomers;
      return userArray.sort((a, b) =>
        `${a.customerFirstName} ${a.customerLastName}`.localeCompare(
          `${b.customerFirstName} ${b.customerLastName}`
        )
      );
    },
    sortedGroups() {
      const userArray = this.allActiveGroups;
      return userArray.sort((a, b) => a.groupName.localeCompare(b.groupName));
    },
  },
  async created() {
    this.getBranches();
    this.getCentres();
    this.getAllUsers();
    this.getAllCustomers();
    this.getAllGroups();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
});
</script>
